export const ORIGINS = Object.freeze({
  sameCategory: "sameCategory",
  "sameCategory-swipeLeft": "sameCategory-swipeLeft",
  "sameCategory-swipeRight": "sameCategory-swipeRight",
  parentCategory: "parentCategory",
  subcategory: "subcategory",
  card: "card",
});

// Add alias for backward compatibility
export { ORIGINS as FLAVOR_PAGE_ORIGINS };
