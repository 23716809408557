import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, spacing } from "src/styles/variables";
import { HeaderS, SubHeaderM } from "src/atoms/Typography";
import Picture, { FITS } from "src/atoms/Picture";

const ListItem = React.memo(
  ({ image, title, subtitle, hasInvertedSizes, isPush = false, ...rest }) => (
    <Outer {...rest}>
      <StyledPicture fit={FITS.cover} small={image} />
      <Copy>
        <Title as={hasInvertedSizes ? HeaderS : SubHeaderM}>{title}</Title>
        {isPush ? (
          <Subtitle as={hasInvertedSizes ? SubHeaderM : HeaderS}>
            {subtitle}
          </Subtitle>
        ) : (
          <Subtitle
            as={hasInvertedSizes ? SubHeaderM : HeaderS}
            forwardedAs="h2"
          >
            {subtitle}
          </Subtitle>
        )}
      </Copy>
    </Outer>
  )
);

ListItem.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isPush: PropTypes.bool,
  hasInvertedSizes: PropTypes.bool,
};

const Outer = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      ${colors.lightShadowGradient[0]},
      ${colors.lightShadowGradient[1]}
    );
  }
`;
const StyledPicture = styled(Picture)`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
`;
const Copy = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 30px;
  left: 30px;
  padding-right: 30px;
  width: calc(100% - 60px);
  max-width: 320px;
  color: ${colors.white};
`;
const Title = styled.span`
  display: block;
  color: inherit;
  opacity: inherit;
  margin: ${spacing.stack.xs};
`;
const Subtitle = styled.span`
  display: block;
  color: inherit;
  opacity: inherit;
`;
export default ListItem;
